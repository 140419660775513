import React from 'react';
import { Link } from 'gatsby';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Checkbox } from 'formik-material-ui';
import * as Yup from 'yup';
import styled from 'styled-components';
import mq from '../../styles/media-queries';

const FormStyles = styled(Form)`
    background: var(--white);
    margin: 15px 0;
    text-align: left;
}
`;

const Fieldset = styled.fieldset`
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
`;

const Input = styled(Field)`
  min-height: 50px;
  font-size: 1.3rem;
  color: var(--darkgrey);
  width: 100%;
  border: ${(props) => props.border || '1px solid #ccc'};
  border-radius: 4px;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.2s;
  touch-action: manipulation;

  &:focus {
    box-shadow: 0 0 0 2px #57BC90;
  }

`;

const LabelStyles = styled.label`
  transition: all 0.2s;
  touch-action: manipulation;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  `

const FieldsetStyles = styled.div`

  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;

`

const TextareaStyles = styled.input`
  height: 100px;
  max-width: 100%;
  resize: none;
`;

const FormCheckbox = styled.span`
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0em;
    border-width: 0;
    border-radius: .3rem;
    background: #f2f3f5;
    border-color: #e8e9eb;
    color: #434955;
    box-shadow: 0 1px 0 0 rgba(0,0,0,.08) inset;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  svg {
    transform: scale(1.5);
  }
  span {
    font-size: 1.6rem;
  }
`

const ButtonSubmit = styled.button.attrs({
  className: 'btn btn-lg ',
})`
  cursor: pointer;
  width: 100%;
  ${mq.md} {
    width: 250px;
  }
`;

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Bitte füllen Sie dieses Feld aus.'),
  email: Yup.string().email('Falsches E-Mail Format').required('Bitte füllen Sie dieses Feld aus.'),
  message: Yup.string().required('Bitte füllen Sie dieses Feld aus.'),
  terms: Yup.boolean().oneOf([true], "Sie müssen den Bedingungen zustimmen, um fortzufahren.")
});

const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const InputFeedback = ({ error }) =>
  error ? <div className="input-feedback">{error}</div> : null;

const ContactForm = ({ form }) => (
  <Formik
    validationSchema={SignupSchema}
    validateOnChange={false}
    validateOnBlur={false}
    initialValues={{
      name: '',
      email: '',
      phone: '',
      message: '',
      terms: false
    }}
    onSubmit={(values, actions) => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': form, ...values }),
      })
        .then(() => {
          alert('Success');
          actions.resetForm();
        })
        .catch(() => {
          alert('Error');
        })
        .finally(() => actions.setSubmitting(false));
    }}
  >
    {({ touched, errors, values, handleChange, isSubmitting, handleBlur, handleSubmit, setFieldValue }) => (
      <FormStyles
        loading={isSubmitting}
        name={form}
        data-netlify
      >
        <FieldsetStyles>
          <Input
            border={errors.name && '1px solid red'}
            placeholder="Ihr Vor und Nachname"
            name="name"
          />
          <LabelStyles htmlFor="message">Dein Name</LabelStyles>
        </FieldsetStyles>
        <FieldsetStyles>

          <Input
            border={touched.email && errors.email && '1px solid red'}
            placeholder="Ihre E-Mail"
            name="email"
          />
          <LabelStyles htmlFor="email">Deine E-Mail</LabelStyles>
        </FieldsetStyles>
        <FieldsetStyles>
          <Input
            border={touched.phone && errors.phone && '1px solid red'}
            placeholder="Ihr Telefonnummer"
            name="phone"
          />
          <LabelStyles htmlFor="phone">Ihr Telefonnummer</LabelStyles>
        </FieldsetStyles>
        <FieldsetStyles>
          <Input
            border={errors.message && '1px solid red'}
            placeholder="Ihre Nachricht"
            name="message"
            component="textarea"
            rows="10"
          />
          <LabelStyles htmlFor="message">Deine Nachricht</LabelStyles>
        </FieldsetStyles>
        <FieldsetStyles>
          <Label>
            <Field component={Checkbox} size="medium" inputProps={{ 'aria-label': 'primary checkbox' }} type="checkbox" name="terms" onChange={(e) => setFieldValue('terms', e.target.checked)} />
            <span>Ich habe die <Link to="/datenschutz" target="_blank" rel="noopener">Datenschutzerklärung</Link> zur Kenntnis genommen und stimme zu.</span>
          </Label>
        </FieldsetStyles>
        <FieldsetStyles>
          <ButtonSubmit type="submit" disabled={isSubmitting}>Nachricht Absenden</ButtonSubmit>
        </FieldsetStyles>
      </FormStyles>
    )}
  </Formik>
);

export default ContactForm;
